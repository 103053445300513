<template>
    <div class="contact-details-main">
        <div v-show="loading" id="semiTransparenDiv"></div>
        <CoolLightBox 
            :items="additional_images" 
            :index="index"
            :useZoomBar="true"
            :effect="'fade'"
            loop
            @close="index = null">
        </CoolLightBox>
        <div class="container-fluid">
            <Header v-if="showHeader" />
            <div class="main-body-wrapper">
                <div class="container-fluid">
                    <!-- breadcrumbs -->
                    <div class="contact-details-top">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item"><a href="#">Core</a></li>
                                <li class="breadcrumb-item">
                                    <!-- <a href="#">Brands</a> -->
                                    <router-link to="/brands-listing" replace>Brands</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">{{brand_name}}</li>
                            </ol>
                        </nav>
                    </div>
                    <!-- brand name -->
                    <div class="heading">
                        <router-link  to="/brands-listing" replace>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M15 8H1" stroke="#0F123F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 15L1 8L8 1" stroke="#0F123F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        <!-- <i class="fa fa-arrow-left" aria-hidden="true"></i> -->
                        </router-link>
                        <p>{{brand_name}}</p>
                    </div>

                    <!-- cover photo and images managemnent -->
                    <div class="main11 two-column">
                        <!-- first -->
                        <div v-show="blank_state" class="first-step">
                            <div class="ban-inner1">
                                <svg width="40px" height="40px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" class="icon">
                                    <path d="M864 248H728l-32.4-90.8a32.07 32.07 0 0 0-30.2-21.2H358.6c-13.5 0-25.6 8.5-30.1 21.2L296 248H160c-44.2 0-80 35.8-80 80v456c0 44.2 35.8 80 80 80h704c44.2 0 80-35.8 80-80V328c0-44.2-35.8-80-80-80zm8 536c0 4.4-3.6 8-8 8H160c-4.4 0-8-3.6-8-8V328c0-4.4 3.6-8 8-8h186.7l17.1-47.8 22.9-64.2h250.5l22.9 64.2 17.1 47.8H864c4.4 0 8 3.6 8 8v456zM512 384c-88.4 0-160 71.6-160 160s71.6 160 160 160 160-71.6 160-160-71.6-160-160-160zm0 256c-53 0-96-43-96-96s43-96 96-96 96 43 96 96-43 96-96 96z"/>
                                </svg>
                                <br>
                                <!-- Button trigger modal -->
                                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter-add-media">
                                Add Media
                                </button>
                            </div>
                        </div>

                        <!-- second -->
                        <div v-show="present_state"   :class="['sec-step ', additional_images.length>1 ? 'two-section complete-section' : 'two-section']">
                            <div  class="bannerimg">
                                <video v-if="cover_photo_type==='video'" :src="cover_photo" controls ></video>
                                <img class="img-fluid" v-if="cover_photo_type==='image'" :src="cover_photo">
                                <img  v-if="!cover_photo" class="dummy-image" :src="require('@/assets/images/dummy.jpg')">

                                <div class="choose-gallery-dropdown">
                                    <div class="dropdown-icon">
                                        <button class="dropbtn">
                                            <svg width="23px" height="23px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" class="icon">
                                                <path d="M864 248H728l-32.4-90.8a32.07 32.07 0 0 0-30.2-21.2H358.6c-13.5 0-25.6 8.5-30.1 21.2L296 248H160c-44.2 0-80 35.8-80 80v456c0 44.2 35.8 80 80 80h704c44.2 0 80-35.8 80-80V328c0-44.2-35.8-80-80-80zm8 536c0 4.4-3.6 8-8 8H160c-4.4 0-8-3.6-8-8V328c0-4.4 3.6-8 8-8h186.7l17.1-47.8 22.9-64.2h250.5l22.9 64.2 17.1 47.8H864c4.4 0 8 3.6 8 8v456zM512 384c-88.4 0-160 71.6-160 160s71.6 160 160 160 160-71.6 160-160-71.6-160-160-160zm0 256c-53 0-96-43-96-96s43-96 96-96 96 43 96 96-43 96-96 96z"/>
                                            </svg>
                                            Change cover background
                                        </button>
                                        <div class="dropdown-content">
                                            <div class="arrow-up"></div>
                                            <div class="upload-btn">
                                                <button>
                                                <i class="fa fa-picture-o" aria-hidden="true"></i>
                                                <input type="file" id="myfile7" name="myfile" readonly  ref="change_cover_photo" @change="ChangeCoverPhoto($event)">
                                                Upload photo/video
                                                </button>
                                            </div>
                                            <hr>
                                            <div class="cover-modal">
                                                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal-98">
                                                <i class="fa fa-external-link" aria-hidden="true"></i> Choose from gallery
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="additional_images.length>1" class="sub-img">
                                <div v-for="(image,index) in imagesToDisplay" :key="index" class="inner">
                                    <img v-if="index<2 && image" class="bottom-left"  :src="image" > 
                                </div>
                            </div>
                            <div class="sub-img 2">
                                <div class="inner">
                                    <div v-if="additional_images.length>=1" class="view-image-gallery">
                                        <img :src="additional_images[0]">
                                        <!-- gallery section -->
                                        <div v-if="additional_images.length>3" class="button-view-gallery">
                                            <div class="view-gall">
                                                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal-99">
                                                View gallery
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <img v-else class="dummy-image" :src="require('@/assets/images/dummy.jpg')">
                                    <!-- add media button -->
                                    <div class="banner5" >
                                        <img class="img-fluid bottom-left1" :src="require('@/assets/images/banner-5.png')">
                                        <div class="ban-inner">
                                            <button>
                                                <input type="file" id="myfile1" name="myfile" readonly  ref="files" multiple v-on:change="AddImages($event)">
                                                <svg width="40px" height="40px" viewBox="0 0 1024 1024" 
                                                xmlns="http://www.w3.org/2000/svg" class="icon">
                                                    <path d="M864 248H728l-32.4-90.8a32.07 32.07 0 0 0-30.2-21.2H358.6c-13.5 0-25.6 8.5-30.1 21.2L296 248H160c-44.2 0-80 35.8-80 80v456c0 44.2 35.8 80 80 80h704c44.2 0 80-35.8 80-80V328c0-44.2-35.8-80-80-80zm8 536c0 4.4-3.6 8-8 8H160c-4.4 0-8-3.6-8-8V328c0-4.4 3.6-8 8-8h186.7l17.1-47.8 22.9-64.2h250.5l22.9 64.2 17.1 47.8H864c4.4 0 8 3.6 8 8v456zM512 384c-88.4 0-160 71.6-160 160s71.6 160 160 160 160-71.6 160-160-71.6-160-160-160zm0 256c-53 0-96-43-96-96s43-96 96-96 96 43 96 96-43 96-96 96z"/>
                                                </svg>
                                                <p>Add Media</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                 <!-- Button trigger modal -->
                <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter-add-media">
                Add Media
                </button> -->



                <!-- logo -->
                <div class="the-dragon-chamber">
                    <div class="the-dragon-chamber-img">
                        <img class="img-fluid" v-if='logo' :src="logo"> 
                        <img v-else class="img-fluid dummy-image" :src="require('@/assets/images/dummy.jpg')">
                        <div class="dragon-inner">
                            <button> 
                            <input type="file" id="myfile2" name="myfile" readonly ref="logo_photo" @change="ChangeLogo($event)">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                    <div class="edit-tags" >
                        <div v-for="(chosen_tag,index) in chosen_tags_list" :key="index">
                            <p  class="badge badge-primary">{{chosen_tag}}</p>
                        </div>
                        <div class="ed-tags "><a href="#" data-toggle="modal" data-target="#exampleModal1">EDIT TAGS</a>
						</div>
                    </div>

                    <!-- edit tags modal -->
                    <div class="edit-tags-modal">
                        <!-- Button trigger modal -->
                        <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header text-center">
                                        <h5 class="modal-title w-100" id="exampleModalLabel1">Tags
                                        </h5>
                                        <div class="info1-icon">
                                            <div class="dropdown">
                                                <button class="btn btn-warning dropdown-toggle" type="button" data-toggle="dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                                        <g>
                                                            <path fill="none" d="M0 0h24v24H0z"/>
                                                            <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"/>
                                                        </g>
                                                    </svg>
                                                </button>
                                                <ul class="dropdown-menu">
                                                    <li>Can't find the tag you want on the list? Drop an email to <a href="mailto:kitchensupport@tabletop.sg">kitchensupport@tabletop.sg</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="available-chosen">
                                            <div class="available-tags">
                                                <div class="avail-heading">
                                                    <p>Available Tags <span>(Select max 5)</span></p>
                                                </div>
                                                <div class="search-tags">
                                                    <form>
                                                        <v-select name="menu" id="menu-select" multiple v-model="selected_me" :options="available_tags_list"  ref="available_select"   />
                                                    </form>
                                                </div>
                                            </div>
                                            <div class="direct">
                                                <div class="direct-inner">
                                                    <i class="fa fa-arrow-left" aria-hidden="true" @click="remove_selected_tags()"></i>
                                                    <i class="fa fa-arrow-right" aria-hidden="true"  @click="add_selected_tags()"></i>
                                                </div>
                                            </div>
                                            <div class="chosen-tags">
                                                <div class="avail-heading">
                                                    <p>Chosen Tags</p>
                                                </div>
                                                <div class="choose-tags">
                                                        <form>
                                                            <v-select name="menu" id="menu-select1" multiple v-model="chosen_me" :options="chosen_tags_list"  ref="chosen_select"/>
                                                        </form>
                                                </div>
                                                <div class="remove-all">
                                                    <p @click="remove_all()">REMOVE ALL</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="footer-para">
                                        <p>Hold down “Control”, or “Command” on a mac, to select more than one.</p>
                                    </div>
                                    <div class="modal-footer">
                                        <button @click="discard_tags()" type="button" class="btn btn-secondary" >DISCARD CHANGES</button>
                                        <button @click="save_tags()" type="button" class="btn btn-primary">SAVE CHANGES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>








                </div>
                <!-- brand name  -->
                <div class="dragonchamber">
                    <p>{{brand_name}}</p>
                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                </div>
                <!-- status -->
                <div class="status">
                    <p>Status:</p>
                    <div class="theme-switch-wrapper1">
                        <label class="theme-switch1" for="checkbox1">
                            <input type="checkbox" id="checkbox1" v-model="brand_status" >
                            <div :class="['slider1 ', brand_status === true ? 'round' : 'round disabled']">
                                <span class="on">Active</span>
                                <span class="off">Inactive</span>
                            </div>
                        </label>
                    </div>
                </div>

                <!-- tabs -->
                <div class="tabs-list">
                    <!-- navbar -->
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Intro</a>
                            <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Contact</a>
                            <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Schedule</a>
                            <a class="nav-item nav-link" id="nav-menu-tab" data-toggle="tab" href="#nav-menu" role="tab" aria-controls="nav-menu" aria-selected="false">Menu Items</a>
                            <a class="nav-item nav-link" id="nav-fees-tab" data-toggle="tab" href="#nav-fees" role="tab" aria-controls="nav-fees" aria-selected="false">Fees and Promos</a>
                            

                            <a class="nav-item nav-link" id="nav-notice-tab" data-toggle="tab" href="#nav-notice" role="tab" aria-controls="nav-notice" aria-selected="false">Notice</a>

 
                        </div>
                    </nav>
                    <!-- tabs content -->
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <brands-intro :brandName.sync="brand_name" ></brands-intro>
                        </div>
                        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <brands-contact></brands-contact>
                        </div>
                        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                            <brands-schedule></brands-schedule>
                        </div>
                        <div class="tab-pane fade" id="nav-menu" role="tabpanel" aria-labelledby="nav-profile-menu">
                            <brands-menu></brands-menu>
                        </div>
                        <div class="tab-pane fade" id="nav-fees" role="tabpanel" aria-labelledby="nav-fees-tab">
                            <fee-and-promos></fee-and-promos>
                        </div>

                        <div class="tab-pane fade" id="nav-notice" role="tabpanel" aria-labelledby="nav-notice-tab">
                            <brands-notice></brands-notice>
                        </div>





                    </div>
                </div>

                <!-- change brand name modal -->
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mx-auto" id="exampleModalLabel">Edit brand title</h5>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-row">
                                        <div class="col">
                                            <input type="text" class="form-control" v-model='brand_name' >
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-primary" data-dismiss="modal"  @click="update_brand_name()">Update</button>
                            </div>
                        </div>
                    </div>
                </div>

            
                <!-- cover photo modal -->
                <div class="modal fade" id="exampleModal-98" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel-98" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mx-auto" id="exampleModalLabel-98">Choose Cover Background</h5>
                            </div>
                            <div class="modal-body">
                                <div class="choose-cover">
                                    <div class="banner5">
                                        <img class="img-fluid" :src="require('@/assets/images/banner-5.png')">
                                        <div class="ban-inner">
                                            <button>
                                                <input type="file" id="myfile7" name="myfile" readonly  ref="change_cover_photo" @change="ChangeCoverPhoto($event)">
                                                <svg width="40px" height="40px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" class="icon">
                                                    <path d="M864 248H728l-32.4-90.8a32.07 32.07 0 0 0-30.2-21.2H358.6c-13.5 0-25.6 8.5-30.1 21.2L296 248H160c-44.2 0-80 35.8-80 80v456c0 44.2 35.8 80 80 80h704c44.2 0 80-35.8 80-80V328c0-44.2-35.8-80-80-80zm8 536c0 4.4-3.6 8-8 8H160c-4.4 0-8-3.6-8-8V328c0-4.4 3.6-8 8-8h186.7l17.1-47.8 22.9-64.2h250.5l22.9 64.2 17.1 47.8H864c4.4 0 8 3.6 8 8v456zM512 384c-88.4 0-160 71.6-160 160s71.6 160 160 160 160-71.6 160-160-71.6-160-160-160zm0 256c-53 0-96-43-96-96s43-96 96-96 96 43 96 96-43 96-96 96z"/>
                                                </svg>
                                                <p>Add Media</p>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="modal-images " v-for="(image,index) in additional_images" :key="index" @click="cover_file_selected_from_gallery($event,image)">
                                        <img  :src="image" >
                                     
                                    </div>

                                    
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">CANCEL</button>
                                <button type="button" class="btn btn-primary" data-dismiss="modal" @click="updateCoverPhoto()">ADD AS COVER BACKGROUND</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- view gallery modal -->
                <div class="modal fade" id="exampleModal-99" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel-99" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <i  @click="edit_images_list()" class="fa fa-pencil-square-o" aria-hidden="true"> EDIT</i>
                                <h5 class="modal-title mx-auto" id="exampleModalLabel-99">Gallery</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="choose-cover1">
                                    <div class="banner5">
                                        <img class="img-fluid" :src="require('@/assets/images/banner-5.png')">
                                        <div class="ban-inner">
                                            <button>
                                                <input type="file" id="myfile1" name="myfile" readonly  ref="files" multiple v-on:change="AddImages($event)">
                                                <svg width="40px" height="40px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" class="icon">
                                                    <path d="M864 248H728l-32.4-90.8a32.07 32.07 0 0 0-30.2-21.2H358.6c-13.5 0-25.6 8.5-30.1 21.2L296 248H160c-44.2 0-80 35.8-80 80v456c0 44.2 35.8 80 80 80h704c44.2 0 80-35.8 80-80V328c0-44.2-35.8-80-80-80zm8 536c0 4.4-3.6 8-8 8H160c-4.4 0-8-3.6-8-8V328c0-4.4 3.6-8 8-8h186.7l17.1-47.8 22.9-64.2h250.5l22.9 64.2 17.1 47.8H864c4.4 0 8 3.6 8 8v456zM512 384c-88.4 0-160 71.6-160 160s71.6 160 160 160 160-71.6 160-160-71.6-160-160-160zm0 256c-53 0-96-43-96-96s43-96 96-96 96 43 96 96-43 96-96 96z"/>
                                                </svg>
                                                <p>Add Images</p>
                                            </button>
                                        </div>
                                    </div>
                                
                                    <div class="view-gallery-icon" v-for="(image,index) in additional_images" :key="index" >
                                        <img    @click="view_image(index)" :src="image" >
                                        <!-- <div class="edita-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="14px" height="14px" viewBox="0 0 276.167 276.167" style="enable-background:new 0 0 276.167 276.167;" xml:space="preserve">
                                                <g>
                                                    <g>
                                                        <path d="M33.144,2.471C15.336,2.471,0.85,16.958,0.85,34.765s14.48,32.293,32.294,32.293s32.294-14.486,32.294-32.293    S50.951,2.471,33.144,2.471z"/>
                                                        <path d="M137.663,2.471c-17.807,0-32.294,14.487-32.294,32.294s14.487,32.293,32.294,32.293c17.808,0,32.297-14.486,32.297-32.293    S155.477,2.471,137.663,2.471z"/>
                                                        <path d="M243.873,67.059c17.804,0,32.294-14.486,32.294-32.293S261.689,2.471,243.873,2.471s-32.294,14.487-32.294,32.294    S226.068,67.059,243.873,67.059z"/>
                                                        <path d="M32.3,170.539c17.807,0,32.297-14.483,32.297-32.293c0-17.811-14.49-32.297-32.297-32.297S0,120.436,0,138.246    C0,156.056,14.493,170.539,32.3,170.539z"/>
                                                        <path d="M136.819,170.539c17.804,0,32.294-14.483,32.294-32.293c0-17.811-14.478-32.297-32.294-32.297    c-17.813,0-32.294,14.486-32.294,32.297C104.525,156.056,119.012,170.539,136.819,170.539z"/>
                                                        <path d="M243.038,170.539c17.811,0,32.294-14.483,32.294-32.293c0-17.811-14.483-32.297-32.294-32.297    s-32.306,14.486-32.306,32.297C210.732,156.056,225.222,170.539,243.038,170.539z"/>
                                                        <path d="M33.039,209.108c-17.807,0-32.3,14.483-32.3,32.294c0,17.804,14.493,32.293,32.3,32.293s32.293-14.482,32.293-32.293    S50.846,209.108,33.039,209.108z"/>
                                                        <path d="M137.564,209.108c-17.808,0-32.3,14.483-32.3,32.294c0,17.804,14.487,32.293,32.3,32.293    c17.804,0,32.293-14.482,32.293-32.293S155.368,209.108,137.564,209.108z"/>
                                                        <path d="M243.771,209.108c-17.804,0-32.294,14.483-32.294,32.294c0,17.804,14.49,32.293,32.294,32.293    c17.811,0,32.294-14.482,32.294-32.293S261.575,209.108,243.771,209.108z"/>
                                                    </g>
                                                </g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                            </svg>
                                        </div>  -->
                                        <div v-show="edit_images" class="cross-ic"  @click="delete_image(index,image)" >
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 490 490" style="enable-background:new 0 0 490 490;" xml:space="preserve">
                                                    <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490   489.292,457.678 277.331,245.004 489.292,32.337 "/>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- add media modal -->
                <div class="modal fade" id="exampleModalCenter-add-media" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle-add-media" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mx-auto" id="exampleModalLongTitle-add-media">Upload Cover Media</h5>
                            </div>
                            <div class="modal-body">
                                <div class="modal-top-heading">
                                    <h5>Cover background</h5>
                                    <p>Upload a video or an image.</p>
                                </div>
                                <div  :class="['add-media-video ', cover_photo=='' ? 'full-width-add-media' : '']">
                                    <div class="video-player">
                                        <video v-if="cover_photo_type==='video'" :src="cover_photo" controls ></video>
                                        <img class="img-fluid" v-else :src="cover_photo">
                                    </div>
                                    <div class="first-step-add-media-top">
                                        <div class="image-video-inner1">
                                            <div class="file-upload-cover">
                                                <div class="image-upload-wrap-cover" >
                                                    <div class="drag-text">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="67" height="49" viewBox="0 0 67 49" fill="none">
                                                            <rect x="0.5" y="14.5" width="50" height="34" fill="white" stroke="#97A5B5" stroke-dasharray="3 3"/>
                                                            <path d="M56 39.5L58.5 34.5L58 19.5L52.5 12.5L45.5 7.5L41 1H28L24 7.5H14L11 10.5V36.5L14.5 39.5H56Z" fill="white"/>
                                                            <path d="M58.167 1V14.8051" stroke="#97A5B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            <path d="M51.2646 7.90234L65.0698 7.90234" stroke="#97A5B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            <path d="M34.5837 31.0151C39.3201 31.0151 43.1596 27.1755 43.1596 22.4392C43.1596 17.7028 39.3201 13.8633 34.5837 13.8633C29.8474 13.8633 26.0078 17.7028 26.0078 22.4392C26.0078 27.1755 29.8474 31.0151 34.5837 31.0151Z" stroke="#97A5B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M59.1675 19.4069V35.3036C59.1675 36.7061 58.6104 38.0511 57.6187 39.0428C56.627 40.0345 55.282 40.5916 53.8795 40.5916H15.288C13.8855 40.5916 12.5405 40.0345 11.5488 39.0428C10.5571 38.0511 10 36.7061 10 35.3036V11.7199C10 10.3174 10.5571 8.97242 11.5488 7.98074C12.5405 6.98905 13.8855 6.43193 15.288 6.43193H23.3287L27.3198 0.4453C27.5052 0.167101 27.8175 0 28.1518 0H41.0157C41.35 0 41.6623 0.167101 41.8477 0.4453L45.5129 5.94302V8.43193H45.3036C44.9693 8.43193 44.657 8.26483 44.4716 7.98663L40.4805 2H28.687L24.6959 7.98663C24.5104 8.26483 24.1982 8.43193 23.8639 8.43193H15.288C14.4159 8.43193 13.5796 8.77834 12.963 9.39495C12.3464 10.0116 12 10.8479 12 11.7199V35.3036C12 36.1757 12.3464 37.012 12.963 37.6286C13.5796 38.2452 14.4159 38.5916 15.288 38.5916H53.8795C54.7516 38.5916 55.5879 38.2452 56.2045 37.6286C56.8211 37.012 57.1675 36.1757 57.1675 35.3036V19.4069H59.1675Z" fill="#97A5B5"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="drag-drop">
                                                <h6>Drag & drop or 
                                                    <span>
                                                    <input class="file-upload-input-cover" type='file'  accept="image/*" @change="ChangeCoverPhotoInitial($event)" name="image" ref="myFileInput"/>
                                                    browse
                                                    </span> to upload</h6>
                                                <p>Video size: 846 px x 1080 px (4:5 aspect ratio)<br>Max file size: 15 MB</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div v-show="cover_media_progress_bar" class="uploading-modal">
                                    <div class="top-test-upload">
                                        <div class="uploading-content">
                                            <h5>Uploading </h5>
                                        </div>
                                        <div class="right-icons-content">
                                            <div  class="cross-icon"  @click="cancelRequest()">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 490 490" style="enable-background:new 0 0 490 490;" xml:space="preserve">
                                                    <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490   489.292,457.678 277.331,245.004 489.292,32.337 "/>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <h6>{{cover_media_progress_value}}% | {{cover_time_left}} Seconds remaining</h6>
                                    <div class="progress">
                                        <div    :class="['', cover_file_error == '' ? 'progress-bar' : ' show-error-bg']"  
                                        role="progressbar" 
                                        :style="{'width': `${parseInt(cover_media_progress_value)}%`}"
                                            aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p v-if="cover_file_error"  class="show-error"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{cover_file_error_message}}</p>
                                </div>


                                <div class="modal-top-heading1">
                                    <h5>Additional images</h5>
                                    <p>Upload any additional images you want to show in the gallery.</p>
                                </div>
                                <div v-if="additional_images.length>0"     class="additional-images">
                                    <!-- <div id="recipeCarousel2" class="carousel slide w-100 " data-ride="carousel">
                                        <div class="carousel-inner w-100 vv-3" role="listbox">
                                            <div   v-for="(image,index) in additional_images" :key="index"   :class="['carousel-item ', index==0 ? 'active' : '']">
                                                <div>
                                                    <div class="add-image-inner">
                                                        <div class="cross-ic"  @click="delete_image(index,image)" >
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 490 490" style="enable-background:new 0 0 490 490;" xml:space="preserve">
                                                                <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490   489.292,457.678 277.331,245.004 489.292,32.337 "/>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                            </svg>
                                                        </div>
                                                        <img   :src="image" >

                                                    </div>
                                                </div>
                                            </div>
                                       
                                        </div>
                                        <a class="carousel-control-prev" href="#recipeCarousel2" role="button" data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#recipeCarousel2" role="button" data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                        </a>
                                    </div> -->
                                    
                                    <div class="add-image-inner">
                                        <carousel :autoplay="true" :nav="true" >
                                            <img     v-for="(image,index) in additional_images" :key="index"   :src="image" >
                                        </carousel>
                                    </div>
                                </div>

                                <div class="first-step-add-media-top">
                                    <div class="image-video-inner1">
                                        <div class="file-upload-cover">
                                            <div class="image-upload-wrap-cover" >
                                                
                                                <div class="drag-text">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="67" height="49" viewBox="0 0 67 49" fill="none">
                                                        <rect x="0.5" y="14.5" width="50" height="34" fill="white" stroke="#97A5B5" stroke-dasharray="3 3"/>
                                                        <path d="M56 39.5L58.5 34.5L58 19.5L52.5 12.5L45.5 7.5L41 1H28L24 7.5H14L11 10.5V36.5L14.5 39.5H56Z" fill="white"/>
                                                        <path d="M58.167 1V14.8051" stroke="#97A5B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M51.2646 7.90234L65.0698 7.90234" stroke="#97A5B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M34.5837 31.0151C39.3201 31.0151 43.1596 27.1755 43.1596 22.4392C43.1596 17.7028 39.3201 13.8633 34.5837 13.8633C29.8474 13.8633 26.0078 17.7028 26.0078 22.4392C26.0078 27.1755 29.8474 31.0151 34.5837 31.0151Z" stroke="#97A5B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M59.1675 19.4069V35.3036C59.1675 36.7061 58.6104 38.0511 57.6187 39.0428C56.627 40.0345 55.282 40.5916 53.8795 40.5916H15.288C13.8855 40.5916 12.5405 40.0345 11.5488 39.0428C10.5571 38.0511 10 36.7061 10 35.3036V11.7199C10 10.3174 10.5571 8.97242 11.5488 7.98074C12.5405 6.98905 13.8855 6.43193 15.288 6.43193H23.3287L27.3198 0.4453C27.5052 0.167101 27.8175 0 28.1518 0H41.0157C41.35 0 41.6623 0.167101 41.8477 0.4453L45.5129 5.94302V8.43193H45.3036C44.9693 8.43193 44.657 8.26483 44.4716 7.98663L40.4805 2H28.687L24.6959 7.98663C24.5104 8.26483 24.1982 8.43193 23.8639 8.43193H15.288C14.4159 8.43193 13.5796 8.77834 12.963 9.39495C12.3464 10.0116 12 10.8479 12 11.7199V35.3036C12 36.1757 12.3464 37.012 12.963 37.6286C13.5796 38.2452 14.4159 38.5916 15.288 38.5916H53.8795C54.7516 38.5916 55.5879 38.2452 56.2045 37.6286C56.8211 37.012 57.1675 36.1757 57.1675 35.3036V19.4069H59.1675Z" fill="#97A5B5"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="drag-drop">
                                            <h6>Drag & drop or 
                                                <span>
                                                <input class="file-upload-input-cover" type='file'  accept="image/*" readonly  ref="additional_upload_files" multiple v-on:change="AddImagesInitial($event)"/>
                                                browse
                                                </span> to upload</h6>
                                            <p>Max size: 500KB</p>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="additional_images_progress_bar" class="uploading-modal">
                                    <div class="top-test-upload">
                                        <div class="uploading-content">
                                            <h5>Uploading </h5>
                                        </div>
                                        <div class="right-icons-content">
                                            
                                            <div class="cross-icon"  @click="cancelRequest()">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 490 490" style="enable-background:new 0 0 490 490;" xml:space="preserve">
                                                    <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490   489.292,457.678 277.331,245.004 489.292,32.337 "/>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                    <g></g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <h6>{{additional_media_progress_value}}%| {{additional_time_left}} Seconds remaining</h6>
                                    <div class="progress">
                                        <div  :class="['', additional_file_error == '' ? 'progress-bar' : ' show-error-bg']"   role="progressbar"  :style="{'width': `${parseInt(additional_media_progress_value)}%`}"  aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p v-if="additional_file_error"  class="show-error"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{additional_file_error_message}}</p>
                                </div>

                            </div>
                            <!-- <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">CANCEL</button>
                                <button type="button" class="btn btn-warning">FINISH UPLOAD</button>
                            </div> -->
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>
<script>
    import Header from '../layouts/Header.vue';
    import BrandsContact from '../brands/BrandsContact.vue'
    import BrandsIntro from './BrandsIntro.vue'
    import BrandsMenu from './BrandsMenu.vue'
    import FeeAndPromos from './FeeAndPromos.vue';
    import BrandsNotice from './BrandsNotice.vue';

    const axios = require('axios').default;
    const baseUrl =  process.env.VUE_APP_BASE_URL || 'http://192.168.0.159:8080'
    // const CancelToken = axios.CancelToken;
    // const source = CancelToken.source();
    const CancelToken = axios.CancelToken;
    let cancel;
import carousel from 'vue-owl-carousel'
import BrandsSchedule from './BrandsSchedule.vue';

    export default {
    components: { carousel,BrandsContact, BrandsIntro, BrandsMenu,Header, FeeAndPromos,BrandsNotice, BrandsSchedule},
        name: 'BrandsDefault',
        data () {
            return {
                // general vars
                loading:false,
                showHeader: true,
                // default vars
                logo_file:'',
                brand_name:'',
                logo:'',
                brand_status:false,
                cover_photo:'',
                cover_photo_type:'',
                // tags
                chosen_tags_list:[],
                chosen_tags_listBeforeEdit:[],
                available_tags_list:[],
                chosen_tags:'',
                available_tags:'',
                select_tags_to_choose_list:[],
                select_tags_to_remove_list:[],
                selected_me:[],
                chosen_me:[],
                additional_images:[],

                // other vars
                blank_state:true,
                present_state:false,
                empty_array:[],
                index: null,
                file_url:'',
                // add media modal vars
                // cover media
                cover_file:'',
                cover_media_progress_bar:false,
                cover_media_progress_value:0,
                cover_file_error:false,
                cover_file_error_message:'',
                cover_time_left:'',

                // // additional
                additional_files:'',
                additional_images_progress_bar:false,
                additional_media_progress_value:0,
                additional_images_upload:[],
                additional_file_error:false,
                additional_file_error_message:'',
                additional_time_left:'',
                files:'',

                edit_images:false
            
            }
            },
        methods: {
            async ChangeCoverPhoto(e) {
                var cover_files = e.target.files || e.dataTransfer.files;
                if (!cover_files.length)
                    return;
                // checking type of file
                if(cover_files[0]['type'].split('/')[0] != 'image' && cover_files[0]['type'].split('/')[0] != 'video'  ){
                    this.$toasted.error("please upload valid file type: image/video")
                    return;
                }
                // checking size of file
                var size = parseFloat(cover_files[0].size / 1024).toFixed(2);
                if(cover_files[0]['type'].split('/')[0] === 'image'){
                    if(size>500){
                        this.$toasted.error('Image size should not be more than 500KB')
                        return;
                    }
                }
                else{
                    if(size>15000){
                        this.$toasted.error('Video size should not be more than 15mb')
                        return;
                    }
                }

                this.cover_file = cover_files[0];
                let formData = new FormData();
                formData.append('media', this.cover_file);
                try{
                    this.loading = true;
                    const headers = { 
                        "Content-Type": "multipart/form-data",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    var brand_id = this.$store.state.brand
                    var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                    const response =  await axios.patch(api_url,formData,{headers})
                    this.loading = false;
                    this.cover_photo = response.data.media;
                    if((response.data.media) || (response.data.additional_images.length>0)){
                        this.blank_state=false;
                        this.present_state=true;
                        this.cover_photo_type=response.data.media_type
                        this.cover_photo=response.data.media;
                    }
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }
            },

            async ChangeCoverPhotoInitial(e) {
                this.cover_file_error=false
                var cover_files = e.target.files || e.dataTransfer.files;
                if (!cover_files.length)
                    return;
                // checking type of file
                if(cover_files[0]['type'].split('/')[0] != 'image' && cover_files[0]['type'].split('/')[0] != 'video'  ){
                    this.cover_file_error=true
                    this.cover_file_error_message='please upload valid file type: image/video'
                    this.$toasted.error("please upload valid file type: image/video")
                    return;
                }

                // checking size of file
                var size = parseFloat(cover_files[0].size / 1024).toFixed(2);
                if(cover_files[0]['type'].split('/')[0] === 'image'){
                    if(size>500){
                        this.cover_file_error=true
                        this.cover_file_error_message='Image size should not be more than 500KB'
                        this.$toasted.error('Image size should not be more than 500KB')
                        return;
                    }
                }
                else{
                    if(size>15000){
                        this.cover_file_error=true
                        this.cover_file_error_message='Video size should not be more than 15mb'
                        this.$toasted.error('Video size should not be more than 15mb')
                        return;
                    }
                }

                this.cover_file = cover_files[0];
                let formData = new FormData();
                formData.append('media', this.cover_file);
                try{
                    this.cover_media_progress_bar=true
                    // this.loading = true;
                    const headers = { 
                        "Content-Type": "multipart/form-data",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    var brand_id = this.$store.state.brand
                    var api_url = `${baseUrl}/api/brands/`+brand_id+`/`

                    const response =  await axios.patch(api_url,formData,
                        {
                            headers:headers, 
                            cancelToken: new CancelToken(function executor(c) {
                                // An executor function receives a cancel function as a parameter
                                cancel = c;
                            }),
                            // cancelToken: source.token,
                            onUploadProgress: function( progressEvent ) {
                                this.cover_media_progress_value = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
                                // Time Remaining
                                var seconds_elapsed =   ( new Date().getTime() - new Date(progressEvent.timeStamp).getTime() )/1000;
                                var bytes_per_second = progressEvent.loaded / seconds_elapsed;
                                var remaining_bytes =   progressEvent.total - progressEvent.loaded;
                                this.cover_time_left = remaining_bytes / bytes_per_second;
                            }.bind(this)
                        },
                    )
                    // this.loading = false;
                    this.cover_media_progress_bar=false
                    this.cover_photo = response.data.media;
                    if((response.data.media) || (response.data.additional_images.length>0)){
                        this.blank_state=false;
                        this.present_state=true;
                        this.cover_photo_type=response.data.media_type
                        this.cover_photo=response.data.media;
                    }
                }
                catch (e) {
                    // this.loading = false;
                    console.log('error is--------',e)
                    if(axios.isCancel(e) == true){
                        this.cover_file_error=true
                        this.cover_file_error_message='Uploading cancelled by the user'
                        this.$toasted.error("Uploading cancelled by the user")
                    }
                    else{
                        this.cover_media_progress_bar=false
                        var i;
                        for (i = 0; i < e.response.data.errors.length; ++i) {
                            this.$toasted.error(e.response.data.errors[i].message)
                        }
                        if(e.response.status== 450){ // logout the user
                            await this.$store.dispatch("logout");
                            await this.$router.push('/')
                        }else if (e.response.status== 401){
                            await this.$RefreshToken()
                        } 
                    }
                    
                   
                }
            },

            cancelRequest(){
                // source.cancel();
                cancel();

            },

            async AddImagesInitial(e){
                try{
                    this.additional_file_error=false
                    var logo_files = e.target.files || e.dataTransfer.files;
                    if (!logo_files.length)
                        return;
                    
                    this.files = logo_files;
                    var i;
                    let formData = new FormData();
                    for( i = 0; i < this.files.length; i++ ){
                        let file = this.files[i];
                        // validating type of files
                        if(file['type'].split('/')[0] != 'image'  ){
                            this.additional_file_error=true
                            this.additional_file_error_message='please upload valid file type: image'
                            this.$toasted.error("please upload valid file type: image")
                            return;
                        }
                        // validating size of files
                        var size = parseFloat(file.size / 1024).toFixed(2);
                        if(size>500){
                            this.additional_file_error=true
                            this.additional_file_error_message='Image size should not be more than 500KB'
                            this.$toasted.error('Image size should not be more than 500KB')
                            return;
                        }
                        formData.append('additional_images', file);
                    }

                    this.additional_images_progress_bar=true
                    // this.loading = true;
                    const headers = { 
                        "Content-Type": "multipart/form-data",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    var brand_id = this.$store.state.brand
                    var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                    const response =  await axios.patch(api_url,formData,
                    {
                        headers:headers, 
                        // cancelToken: source.token,
                        cancelToken: new CancelToken(function executor(c) {
                            // An executor function receives a cancel function as a parameter
                            cancel = c;
                        }),
                        onUploadProgress: function( progressEvent ) {
                            this.additional_media_progress_value = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
                              // Time Remaining
                            var seconds_elapsed =   ( new Date().getTime() - new Date(progressEvent.timeStamp).getTime() )/1000;
                            var bytes_per_second = progressEvent.loaded / seconds_elapsed;
                            var remaining_bytes =   progressEvent.total - progressEvent.loaded;
                            this.additional_time_left = remaining_bytes / bytes_per_second;
                        }.bind(this)
                    })
                    this.additional_images_progress_bar=false
                    // this.loading = false;
                    this.additional_images = response.data.additional_images;
                    if(response.data.additional_images.length>0){
                        this.blank_state=false;
                        this.present_state=true;
                        this.additional_images= response.data.additional_images;
                    }
                }
                catch (e) {
                    // this.loading = false;
                    console.log('error is',e.response)
                    if(axios.isCancel(e) == true){
                        this.additional_file_error=true
                        this.additional_file_error_message='Uploading cancelled by the user'
                        this.$toasted.error("Uploading cancelled by the user")
                    }
                    else{
                        this.additional_images_progress_bar=false
                        for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                        }
                        if(e.response.status== 450){ // logout the user
                            await this.$store.dispatch("logout");
                            await this.$router.push('/')
                        }else if (e.response.status== 401){
                            await this.$RefreshToken()
                        } 
                    }
  
                }
            },

            async AddImages(e){
                var logo_files = e.target.files || e.dataTransfer.files;
                if (!logo_files.length)
                    return;
                this.files = logo_files;
                var i;
                let formData = new FormData();
                for( i = 0; i < this.files.length; i++ ){
                    let file = this.files[i];
                     // validating type of file
                    if(file['type'].split('/')[0] != 'image'  ){
                        this.$toasted.error("please upload valid file type: image")
                        return;
                    }
                    // validating size of file
                    var size = parseFloat(file.size / 1024).toFixed(2);
                    if(size>500){
                        this.$toasted.error('Image size should not be more than 500KB')
                        return;
                    }
                    formData.append('additional_images', file);
                }
                try{
                    this.loading = true;
                    const headers = { 
                        "Content-Type": "multipart/form-data",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    var brand_id = this.$store.state.brand
                    var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                    const response =  await axios.patch(api_url,formData,{headers})
                    this.loading = false;
                    this.additional_images = response.data.additional_images;
                    if((response.data.media) || (response.data.additional_images.length>0)){
                        this.blank_state=false;
                        this.present_state=true;
                        this.additional_images= response.data.additional_images;
                    }
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }
            },

            async ChangeLogo(e) {
                var logo_files = e.target.files || e.dataTransfer.files;
                if (!logo_files.length)
                    return;
                
                // checking type of file
                if(logo_files[0]['type'].split('/')[0] != 'image' ){
                    this.$toasted.error("please upload valid file type: image")
                    return;
                }
                // checking size of file
                var size = parseFloat(logo_files[0].size / 1024).toFixed(2);
                if(size>500){
                    this.$toasted.error('Image size should not be more than 500KB')
                    return;
                }
                this.logo_file = logo_files[0];
                let formData = new FormData();
                formData.append('logo', this.logo_file);
                try{
                    this.loading = true;
                    const headers = { 
                        "Content-Type": "multipart/form-data",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    var brand_id = this.$store.state.brand
                    var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                    const response =  await axios.patch(api_url,formData,{headers})
                    this.loading = false;
                    this.logo = response.data.logo;
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }
            },

            async update_brand_name(){
                try{
                    this.loading = true;
                    const headers = { 
                        "Content-Type": "application/json",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    const data =  { "name": this.brand_name  };
                    var brand_id = this.$store.state.brand
                    var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                    const response =  await axios.patch(api_url,data,{headers})
                    this.loading = false;
                    this.brand_name = response.data.name;
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }
            },

            add_selected_tags(){
                var i;
                for (i = 0; i < this.selected_me.length; ++i) {
                    if(this.chosen_tags_list.indexOf(this.selected_me[i]) ==-1) {
                        this.chosen_tags_list.push(this.selected_me[i])
                    }
                }
                this.selected_me=[]       
            },

            remove_selected_tags(){
                var i;
                for (i = 0; i < this.chosen_me.length; i++) {
                    this.chosen_tags_list.splice(this.chosen_tags_list.indexOf(this.chosen_me[i] ), 1);
                }
                this.chosen_me=[]
            },

            discard_tags(){
                this.chosen_tags_list =this.chosen_tags_listBeforeEdit
            },
            
            remove_all(){
                this.chosen_tags_list=[]
            },
            
            async save_tags(){
                try{
                    if(this.chosen_tags_list.length<1){
                        this.$toasted.error('please select atleast one tag to update')
                    }
                    else if(this.chosen_tags_list.length>5){
                        this.$toasted.error('A Brand can only have 5 tags!')
                    }
                    else{
                        this.loading = true;
                        const headers = { 
                            "Content-Type": "application/json",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        const data =  { "tags": this.chosen_tags_list};
                        var brand_id = this.$store.state.brand
                        var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                        const response =  await axios.patch(api_url,data,{headers})
                        this.loading = false;
                        await this.$toasted.success("Tags Updated Successfully");
                        this.tags=response.data.tags;
                    }
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }
                

            }, 

            view_image(index) {
                this.index = index
            },

            async cover_file_selected_from_gallery(event,my_image){
                const div_obj =  document.querySelectorAll('.modal-images') // Get element from DOM
                div_obj.forEach(function(div) {
                div.classList.remove('border-active') 
                });
                event.currentTarget.classList.add("border-active");
                var url = my_image;
                var pathname = new URL(url).pathname;
                this.file_url=pathname.substr(pathname.indexOf('/') + 1)
            },

            async updateCoverPhoto(){
                try{
                    if(!this.file_url){
                        this.$toasted.error('please select an image first!')
                        return;
                    }
                    this.loading = true;
                    const headers = { 
                        "Content-Type": "application/json",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    const data={
                        "media_url":this.file_url
                    }
                    var brand_id = this.$store.state.brand
                    var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                    const response =  await axios.patch(api_url,data,{headers})
                    this.loading = false;
                    if(response.data.media){
                        this.blank_state=false;
                        this.present_state=true;
                        this.cover_photo_type=response.data.media_type
                        this.cover_photo=response.data.media;
                    }
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }

            },

            edit_images_list(){
                this.edit_images=true
            },

            async delete_image(index,image){
                try{

                    console.log('index is',index)
                    console.log('image is',image)

                    const response = await fetch(image);
                    console.log('===got response from fetch===',response)

                    const blob = await response.blob();
                    console.log('===got blob===',blob)

                    const file = new File([blob], 'image.jpg', {type: blob.type});
                    console.log('======got file=====',file);
                }
                catch (e) {

                    this.$toasted.error("couldn't delete image due to CORS error")

                }
    

                // console.log('images list is',this.additional_images)
                // this.additional_images.splice(index,1);
                // console.log('images list is-----------',this.additional_images)

                // try{
                //     this.loading = true;
                //     const headers = { 
                //         "Content-Type": "application/json",
                //         "Authorization":'Bearer '+this.$store.state.access_token
                //     };
                //     const data =  { "additional_images": this.additional_images};
                //     var brand_id = this.$store.state.brand
                //     var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                //     const response =  await axios.patch(api_url,data,{headers})
                //     this.loading = false;
                //     // console.log('success',response.data);
                //     await this.$toasted.success("Images Updated Successfully");
                //     this.additional_images=response.data.additional_images;
                // }
                // catch (e) {
                //     this.loading = false;
                //     console.log('error is',e)
                //     var i;
                //     for (i = 0; i < e.response.data.errors.length; ++i) {
                //         this.$toasted.error(e.response.data.errors[i].message)
                //     }
                //     if(e.response.status== 450){ // logout the user
                //         await this.$store.dispatch("logout");
                //         await this.$router.push('/')
                //     }else if (e.response.status== 401){
                //         await this.$RefreshToken()
                //     } 
                // }



            }

        },
        computed: {
            imagesToDisplay: function() {
                if (this.additional_images && this.additional_images.length>1){
                    return this.additional_images.slice(1);
                }
                else{
                    return this.empty_array
                }
    
            }
        },
        mounted() {
            this.$refs.available_select.open = true
            this.$refs.chosen_select.open = true
            
        },
        updated(){
            this.$refs.available_select.open = true
            this.$refs.chosen_select.open = true

        
        },
        async created() {
            try{
                this.loading= true;
                var i;
                const headers = { 
                    "Content-Type": "application/json",
                    "Authorization":'Bearer '+this.$store.state.access_token
                };
                var brand_id = this.$store.state.brand
                var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                const response =  await axios.get(api_url,{headers})
                console.log('------------default data--------------',response.data.notices);
                this.loading = false;
                this.chosen_tags_list = response.data.tags;
                this.chosen_tags_listBeforeEdit = response.data.tags;
                this.brand_name= response.data.name;
                this.logo= response.data.logo;
                this.brand_status=response.data.is_active;
                if((response.data.media) || (response.data.additional_images.length>0)){
                    this.blank_state=false;
                    this.present_state=true;
                    this.cover_photo=response.data.media;
                    this.cover_photo_type=response.data.media_type
                    this.additional_images= response.data.additional_images;

                } 
            }
            catch (e) {
                this.loading = false;
                console.log('error is',e)
                for (i = 0; i < e.response.data.errors.length; ++i) {
                    this.$toasted.error(e.response.data.errors[i].message)
                }
                if(e.response.status== 450){ // logout the user
                    await this.$store.dispatch("logout");
                    await this.$router.push('/')
                }else if (e.response.status== 401){
                    await this.$RefreshToken()
                } 
            }
            
            // hitting api to get available tags data
            try{
                this.loading= true;
                const headers = { 
                    "Content-Type": "application/json",
                    "Authorization":'Bearer '+this.$store.state.access_token
                };
                const response =  await axios.get(`${baseUrl}/api/tags/`,{headers})
                this.loading= false;
                this.available_tags_list = response.data.results;
            }
            catch (e) {
                this.loading= false;
                console.log('error is',e)
                for (i = 0; i < e.response.data.errors.length; ++i) {
                    this.$toasted.error(e.response.data.errors[i].message)
                }
                if(e.response.status== 450){ // logout the user
                    await this.$store.dispatch("logout");
                    await this.$router.push('/')
                }else if (e.response.status== 401){
                    await this.$RefreshToken()
                } 
            }

            // let items = document.querySelectorAll('.carousel .carousel-item')
            // items.forEach((el) => {
            //     // number of slides per carousel-item
            //     const minPerSlide = 4
            //     let next = el.nextElementSibling
            //     for (var i=1; i<minPerSlide; i++) {
            //         if (!next) {
            //             // wrap carousel by using first child
            //             next = items[0]
            //         }
            //         let cloneChild = next.cloneNode(true)
            //         el.appendChild(cloneChild.children[0])
            //         next = next.nextElementSibling
            //     }
            // })



        },
    }
    
</script>
<style></style>