<template>
    <div class="contact-details-main">
        <div v-show="loading" id="semiTransparenDiv"></div>
        <div class="container-fluid">
            <div class="contact-details">
                <div class="container-fluid">
                    <div class="contact-details-heading">
                        <p>BRAND DETAILS</p>
                    </div>
                </div>
                <div class="border-bottom">
                </div>
                <div class="address-details">
                    <div class="container-fluid">
                        <div class="form-2">
                            <div class="form-group">
                                <label for="formGroupExampleInput9">Brand Name<span class="star">*</span></label>
                                <input type="text" class="form-control" id="formGroupExampleInput9" placeholder="" required v-model="brand_name">
                                <small id="emailHelp" class="form-text text-muted">This is the name that your customers will see on Tabletop. Make sure you check your spelling, including capitalisations and spaces.</small>
                                <span v-if="brand_name_error" class="error_alert">{{brand_name_error_message}}</span>

                            </div>
                            <div class="form-group">
                                <label for="formGroupExampleInput10">Brand URL<span class="star">*</span></label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text form-control"  id="basic-addon3">www.tabletop.sg/ </span>
                                    </div>
                                    <input  type="text" class="form-control is-valid" aria-describedby="basic-addon3" id="formGroupExampleInput10" placeholder="" required v-model="brand_url" >
                                    <small id="emailHelp" class="form-text text-muted">Choose a unique web URL that your customers can find you under via Tabletop web or the App search.</small>
                                    <span v-if="brand_url_error" class="error_alert">{{brand_url_error_message}}</span>
                                </div>

                            </div>

                            <div class="sales-channels">
                                <label>Sales Channel</label>
                                <div class="sales-channel-inner">
                                    <div class="switch-1">
                                        Dine-in
                                        <label class="switch-inner">
                                            <input type="checkbox" id="togBtn1" v-model="dine_in" >
                                            <div 
                                            :class="['slider ', dine_in === true ? 'round' : 'round disabled']">
                                            </div>
                                        </label>
                                    </div>
                                    <div class="switch-1">
                                        Take-Away
                                        <label class="switch-inner">
                                            <input type="checkbox" id="togBtn2" v-model="take_away">
                                             <div 
                                            :class="['slider ', take_away === true ? 'round' : 'round disabled']">
                                            </div>
                                        </label>
                                    </div>
                                    <div class="switch-1">
                                        Delivery
                                        <label class="switch-inner">
                                            <input type="checkbox" id="togBtn3"  v-model="delivery">
                                             <div 
                                            :class="['slider ', delivery === true ? 'round' : 'round disabled']">
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="formGroupExampleInput11">Brand Abbreviation<span class="star">*</span></label>
                                <input maxlength="3" type="text" class="form-control is-valid" id="formGroupExampleInput11" placeholder="" required v-model="brand_abbreviation">
                                <span v-if="brand_abbreviation_error" class="error_alert">{{brand_abbreviation_error_message}}</span>

                            </div>
                        </div>
                        <div class="text-area-bottom">
                            <div class="form-group">
                                <label for="exampleFormControlTextarea1">Brand Description<span class="star">*</span></label>
                                <textarea maxlength="300" class="form-control" id="exampleFormControlTextarea1" rows="5" placeholder="" v-model="brand_description"  ></textarea>
                                <span v-if="brand_description_error" class="error_alert">{{brand_description_error_message}}</span>
                                <div id="the-count">
                                    <span id="current" class="limiter">{{charactersLeft}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons">
            <button @click="discard_intro()" class="button1">DISCARD CHANGES</button>
            <button  @click="update_intro()" class="button2">SAVE CHANGES</button>
        </div>
    </div>
   
 
</template>

<script>
    const axios = require('axios').default;
    const baseUrl =  process.env.VUE_APP_BASE_URL || 'http://192.168.0.159:8080'

    export default {
        name: 'BrandsIntro',
        data () {
            return {
                loading: false,
                errors: [],
                
                brand_name:this.brandName,
                brand_url:"",
                brand_abbreviation:"",
                brand_description:"",
                dine_in:false,
                take_away:false,
                delivery:false,

                brand_name_error:false,
                brand_url_error:false,
                brand_abbreviation_error:false,
                brand_description_error:false,

                brand_name_error_message:"",
                brand_url_error_message:"",
                brand_abbreviation_error_message:"",
                brand_description_error_message:"",

                brand_nameBeforeEdit:"",
                brand_urlBeforeEdit:"",
                brand_abbreviationBeforeEdit:"",
                brand_descriptionBeforeEdit:"",
                dine_inBeforeEdit:false,
                take_awayBeforeEdit:false,
                deliveryBeforeEdit:false,
            }
        },
        props:{
            brandName:{
                require:true
            }
        },
        watch:{
            brandName(brand_value){
                this.brand_name=brand_value 
            },
        },
        methods: {

            async update_intro(){
                    this.errors=[]
                    if (this.validate()) {
                        this.loading= true;
                        try{
                            const data =  { 
                                "name": this.brand_name,
                                "slug": this.brand_url, 
                                "abbreviation": this.brand_abbreviation, 
                                "description": this.brand_description, 
                                "dine_in": this.dine_in, 
                                "take_away": this.take_away, 
                                "delivery": this.delivery, 
                            };
                            const headers = { 
                                "Content-Type": "application/json",
                                "Authorization":'Bearer '+this.$store.state.access_token
                            };
                            var brand_id = this.$store.state.brand
                            var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                            const response =  await axios.patch(api_url,data,{headers})
                            this.loading= false;
                            await this.$toasted.success("Brand Intro Details Updated Successfully");
                            this.$emit('update:brandName',response.data.name)

                            this.brand_name= response.data.name;
                            this.brand_url= response.data.slug;
                            this.brand_abbreviation=response.data.abbreviation;
                            this.brand_description=response.data.description;
                            this.dine_in=response.data.dine_in,
                            this.take_away=response.data.take_away,
                            this.delivery=response.data.delivery
                            this.brand_nameBeforeEdit= response.data.name;
                            this.brand_urlBeforeEdit= response.data.slug;
                            this.brand_abbreviationBeforeEdit=response.data.abbreviation;
                            this.brand_descriptionBeforeEdit=response.data.description;
                            this.dine_inBeforeEdit=response.data.dine_in,
                            this.take_awayBeforeEdit=response.data.take_away,
                            this.deliveryBeforeEdit=response.data.delivery
                        }
                        catch (e) {
                            this.loading = false;
                            console.log('error is',e)
                            var i;
                            for (i = 0; i < e.response.data.errors.length; ++i) {
                                this.$toasted.error(e.response.data.errors[i].message)
                            }
                            if(e.response.status== 450){ // logout the user
                                await this.$store.dispatch("logout");
                                await this.$router.push('/')
                            }else if (e.response.status== 401){
                                await this.$RefreshToken()
                            } 
                        }
                    
                    }

            },

            validate(){
                this.brand_name_error=false
                this.brand_url_error=false
                this.brand_abbreviation_error=false
                this.brand_description_error=false
                if ((!this.brand_name) || (this.brand_name.length<=0)) {
                    this.brand_name_error=true
                    this.brand_name_error_message="Please provide Brand Name!"
                    this.errors.push("Please provide Brand Name!")
                    this.$toasted.error('Please provide Brand Name!');
                }

                if ((!this.brand_url) || (this.brand_url.length<=0) ) {
                    this.brand_url_error=true
                    this.brand_url_error_message="Please provide Brand URL!"
                    this.errors.push("Please provide Brand URL!")
                    this.$toasted.error('Please provide Brand URL!');
                }

                if ((!this.brand_abbreviation) || (this.brand_abbreviation.length<=0) ) {
                    this.brand_abbreviation_error=true
                    this.brand_abbreviation_error_message="Please provide Brand abbreviation!"
                    this.errors.push("Please provide Brand abbreviation!")
                    this.$toasted.error('Please provide Brand abbreviation!');
                }

                if ((!this.brand_description) || (this.brand_description.length<=0) ) {
                    this.brand_description_error=true
                    this.brand_description_error_message="Please provide Brand description!"
                    this.errors.push("Please provide Brand description!")
                    this.$toasted.error('Please provide Brand description!');
                }
                if (!this.errors.length) {
                    return true;
                }
                 
            },

            discard_intro(){
                this.brand_name=this.brand_nameBeforeEdit,
                this.brand_url= this.brand_urlBeforeEdit,
                this.brand_abbreviation=this.brand_abbreviationBeforeEdit,
                this.brand_description=this.brand_descriptionBeforeEdit,
                this.dine_in=this.dine_inBeforeEdit
                this.take_away=this.take_awayBeforeEdit
                this.delivery=this.deliveryBeforeEdit
            },   
        },
        mounted(){

        },
        async created() {
            try{
                this.loading= true;
                const headers = { 
                    "Content-Type": "application/json",
                    "Authorization":'Bearer '+this.$store.state.access_token
                };
                var brand_id = this.$store.state.brand
                var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                const response =  await axios.get(api_url,{headers})
                this.loading= false;
                console.log('======intro data=========',response.data);
                this.brand_name= response.data.name;
                this.brand_url= response.data.slug;
                this.brand_abbreviation=response.data.abbreviation;
                this.brand_description=response.data.description;
                this.dine_in=response.data.dine_in,
                this.take_away=response.data.take_away,
                this.delivery=response.data.delivery

                this.brand_nameBeforeEdit= response.data.name;
                this.brand_urlBeforeEdit= response.data.slug;
                this.brand_abbreviationBeforeEdit=response.data.abbreviation;
                this.brand_descriptionBeforeEdit=response.data.description;
                this.dine_inBeforeEdit=response.data.dine_in,
                this.take_awayBeforeEdit=response.data.take_away,
                this.deliveryBeforeEdit=response.data.delivery
            }
            catch (e) {
                this.loading = false;
                console.log('error is',e)
                var i;
                for (i = 0; i < e.response.data.errors.length; ++i) {
                    this.$toasted.error(e.response.data.errors[i].message)
                }
                if(e.response.status== 450){ // logout the user
                    await this.$store.dispatch("logout");
                    await this.$router.push('/')
                }else if (e.response.status== 401){
                    await this.$RefreshToken()
                } 
            }
        

        },
        computed: {
            charactersLeft() {
            var char = this.brand_description.length,
            limit = 300;
            return (char) + " / " + limit ;
            }
        }
    }

</script>


<style>

</style>